.card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.card-img-container {
    width: 91%;
    height: 50vh;
    border-radius: 30px;
    overflow: hidden !important;
}

.card-img {    
    display: block;
    width: 100%;
    overflow: hidden !important;
    object-fit: cover;
    transform: translateY(-15%);
}

.card-chip {
    position: absolute;
    display: flex;
    /* justify-content: center; Center the <p> horizontally */
    align-items: center; /* Center the <p> vertically */
    
    bottom: 2.5rem;
    
    width: 75.5%;
    box-sizing: border-box;
    border: 1px solid #000;
    border-radius: 15px;
    padding: 0.6rem 2rem;
}

.card-chip-white {
    background-color: white;
}

.card-chip-green {
    background-color: #D9FFE8;
}

.card-chip-text {
    margin: 0 0 0 0.5rem;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}   

/* Desktop 16' */
@media (min-width: 1400px) {
    .card-img-container {
        height: 47vh;
    }
}

/* Ipad Pro 11' */
@media (max-width: 1000px) {
    .card-img-container {
        height: 27vh;
        width: 100%;
        border-radius: 15px;
    }
    .card-img {
        transform: scale(2.2) translateX(-7%) translateY(-4%);
        transform-origin: left center;
    }
    .card-chip {
        width: 100%;
        bottom: 0%;
        left: 0%;
        transform: none;
        border-radius: 12px;
        margin:  1rem 0 1rem 0;
        padding: 0.3rem 1.25rem;
        position: relative;
    }
}

@media (max-width: 500px) {
    .card-img-container {
        height: 20vh;
    }
    .card-container {
        height: 0%;
    }
    .card-img {
        transform: scale(2.2) translateX(-7%) translateY(-1%);
    }
    .card-chip {
        position: relative;
    }
    .card-chip-text {
        font-size: 13px;
    }
}

@media (max-width: 375px) {
    .card-img {
        transform: scale(2.2) translateX(-7%) translateY(-4%);
    }   
}