.hero-section {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container {
  position: relative;
  padding: 0;
  width: 100%; /* Ensure it fills the container */
  height: 100%; /* Ensure it fills the container */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image {
  width: 100%;
  height: auto;
  border-radius: 80px;
}

.video-card {
  position: absolute;
  bottom: 15%;
  right: 8%; 
  width: 400px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px; /* Adjust border-radius as needed */
  overflow: hidden; /* This ensures the border-radius is applied correctly */
  transition: all 0.5s ease-in-out; /* Smooth transition effect */
  z-index: 1050; /* Ensure the video card has a higher z-index than the navbar */
}

.video-card.playing {
  width: 80vw; /* Expand to 80vw when playing */
  height: 45vw; /* Maintain aspect ratio */
  bottom: 0%; /* Adjust as needed */
  right: 10%; /* Adjust as needed */
}

.video-placeholder {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px; /* Adjust border-radius as needed */
  overflow: hidden; /* This ensures the border-radius is applied correctly */
  border: 4px solid #17AAFFE5; /* Add your border here */
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(23, 170, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-button svg {
  width: 32px;
  height: 36px;
}

.play-button:hover {
  background: rgba(23, 170, 255, 1);
}

.video-card video {
  width: 100%;
  height: 100%;
  border-radius: 20px; /* Ensure this matches the .video-card radius minus the border width */
  object-fit: cover; /* Ensures the video covers the entire area */
}
