/* contact-section.css */

.contact-section {
  height: 60vh;
  display: flex;
  align-items: center;
}

.large-text {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 6em;
  font-size: 6em;
  line-height: 1.0;
  letter-spacing: -0.02em;
}

.custom-text-agenda{
  text-align: start;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .large-text {
    font-size: 4.3em;
  }  
  .custom-text-agenda{
    text-align: center;
    /* margin-left: -14%; */
    font-size: 2.3em;
    margin-bottom: 10%;
  }
}


.gradient-text {
  background: linear-gradient(90deg, #75CDFF 9.66%, #EBA3FF 86.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.form-container {
  background: none;
  padding: 0;
}

.form-container h3 {
  margin-bottom: 20px;
}

.form-container .form-group {
  margin-bottom: 20px;
}

.form-container .btn {
  width: 100%;
  margin-bottom: 10px;
}

.ml-2 {
  margin-left: 10px;
}

h1.large-text .gradient-text {
  display: inline-block;
}

.form-label{
  margin-bottom: 0.2rem;
  font-weight: 500;
}

.image-container-contact {
  position: absolute;
  left: 0; /* Adjust as needed */
  top: 50%;
  transform: translateY(90%);
  z-index: -1; /* Ensure the image stays behind the text */
}

.form-check-input{
  border: 1px solid black;
}