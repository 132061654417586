@media (min-width: 768px) {
  .px-6 {
    padding-left: 6em;
    padding-right: 6em;
  }

  .px-8 {
    padding-left: 8em;
    padding-right: 8em;
  }

  .px-12 {
    padding-left: 12em;
    padding-right: 12em;
  }


}

.text-gray-600 {
  color: #404040;
}

.gradient-text {
  background: linear-gradient(90deg, #75CDFF 9.66%, #EBA3FF 86.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.fs-10 {
  font-size: 4em;
}

.logo-width {
  width: 60% !important;
}

@media (max-width: 576px) {
  .logo-width {
    width: 40% !important;
  }
}

.mobile-translate {
  z-index: -1;
}

.object-fit-cover {
  object-fit: cover;
}


.bg-blur-right {
  position: absolute;
  top: 0;
  right: -50%;
  z-index: -3;
  transform: translateY(-40%);
}

.bg-blur-right2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -3;
  width: 30%;
}

.date-picker-custom {
  border-radius: 8px;
  border: 1px solid #000;
  padding: 0.4rem 0.8rem;
}