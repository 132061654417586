.logo-width{
  width: 55% !important;
}

@media (max-width: 576px) {
  .logo-width {
    width: 40% !important;
  }
}

.navbar-brand, .navbar-brand a {
  padding: 0;
  margin: 0;
}

.navbar {
    transition: all 0.3s ease-in-out;
    background-color: rgba(255, 255, 255) !important;
    padding-inline: 5rem !important;
  }
  
  .navbar.visible {
    transform: translateY(0);
  }
  
  .navbar.hidden {
    transform: translateY(-100%);
  }
  
  .navbar.expanded-nav {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  
  @media (max-width: 991.98px) {
    .navbar-collapse {
      transition: all 0.3s ease-in-out;
      background-color: rgba(255, 255, 255);
    }
  
    .navbar-nav {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  
    .nav-link {
      text-align: center;
      padding: 0.5rem 0;
    }
  }