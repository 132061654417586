
.action-container{
    position: relative !important;
    height: 100vh;
}
.bg-blur-right-call{
        position: absolute;
        top: 0;
        right: -10%;
        z-index: -3;
        transform: translateY(-40%);
      
}

.image-right2{
    position: absolute;
    top: 0;
    right: -5%;
    z-index: -3;

    transform: translateY(50%);
}

.image-top{
    position: absolute;
    top: 0;
    left: 0 !important;
    transform: translateX(60%);

    z-index: -3;


}

.image-bot{
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(60%);
    z-index: -3;

}

.heading-h1{
    width: 50% !important;
}

@media (max-width: 767px) {
    .heading-h1{
        width: 100% !important;
        font-weight: 700;
        font-size: 4em;
        text-align: start !important;
    }
    .heading-p{
        font-size: 2em !important;
    }
    .action-container{
        height: 80vh;
    }
  }