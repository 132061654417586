.reviews-container{
    position: relative !important;
    height: 100vh;
}

.bg-blur-left{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    transform: translateY(10%);
}

.bg-blur-left2{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    transform: translateY(40%);
    width: 40%;
}

.reviews-container {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}


.slider-container2 {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.slider-wrapper2 {
    width: 100%;
    height: 100%;
}

.review-slider {
    height: 100%;
}

.review-slide {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.review-logo {
    width: 200px; /* Set the desired width */
    height: 200px; /* Set the desired height */
    object-fit: contain; /* Adjusts the logo to fit within the dimensions */
}

.slick-dots{
    position: relative !important;
    bottom: 0;
}
.slick-dots li button{
    font-size: 40px !important;
}