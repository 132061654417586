/* src/index.css or src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  --bs-body-font-family: 'Inter', sans-serif !important;
}

body {
  font-family: 'Inter', sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Override Bootstrap's font variables */
:root {
  --bs-font-sans-serif: 'Inter', sans-serif;
}

/* Any additional global styles */

* {
  font-family: 'Inter', sans-serif !important;
}