.card-background {
  background: linear-gradient(0.01deg, #E5F6FF -0.03%, #CFB8FF 102.64%);
  border-radius: 30px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  position: relative;
  overflow: hidden;
  min-height: 90vh; /* or any other height you prefer */
}

.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  bottom: 5%;
}

.carousel-control {
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  /* width: 70px; */
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control svg {
  width: 100%;
  height: 100%;
}

.carousel-image-wrapper2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
}

.carousel-image-wrapper2 img {
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.carousel-caption2 {
  position: absolute;
  font-weight: 600;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  color: #7733FF;
  z-index: 2;
  background-color: white;
  border-radius: 20px;
}
.custom-p{
  font-size: 3.4em;
  line-height: 1;
  padding-right: 10%;
}

/* Mobile styles */
@media (max-width: 768px) {
  .card-background {
    height: auto;
    padding: 2rem !important;
  }
  .custom-p{
    font-size: 2.9em;
    line-height: 1.1;
    margin-bottom: 0;
  }
  .carousel-caption2 {
    left: 50%;
    transform: translate(-50%, -20%);
    color: #7733FF;
    z-index: 2;
    background-color: white;
    border-radius: 20px;
    width: 90%;
    font-size: 1.2em;
  }

  .carousel-image-wrapper2 {
    height: 60vh;
  }

  .slider-col {
    margin-bottom: 1rem;
  }

  .carousel-controls {
    justify-content: center;
    bottom: -1%;
    left: 0;
    transform: translateX(30%);
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .card-background {
    height: 80vh;
  }

  .carousel-image-wrapper2 {
    height: 70vh;
  }

  .slider-col {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .carousel-controls {
    justify-content: flex-start;
  }
}

.custom-text {
  font-size: 2em; /* Default font size */
}

.custom-button {
  width: 100%; /* Full width by default */
}

/* Mobile styles */
@media (max-width: 767px) {
  .custom-text {
    font-size: 3em; /* Increased font size for mobile */
  }
  .custom-button {
    padding-top: 3%;
    padding-bottom: 3%;
  }

}

/* Desktop styles */
@media (min-width: 768px) {
  .custom-button-col {
    display: flex;
    justify-content: flex-end;
  }

  .custom-button {
    width: 25%; /* Reduced width for desktop */
  }
}

.slick-slide {
  padding: 0 10px; /* Adjust this value to control the spacing between slides */
}

.slick-list {
  overflow: visible; /* This allows the next slide to be visible */
}

.carousel-image-wrapper2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
}

.carousel-image-wrapper2 img {
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
}

/* Ensure the parent container doesn't clip the overflow */
.slider-col {
  overflow: visible;
}

/* Mobile styles */
@media (max-width: 767px) {
  .carousel-image-wrapper2 {
    height: 50vh;
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .carousel-image-wrapper2 {
    height: 70vh;
  }
}
.slider-container {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .slider-container {
    width: calc(100% + 15px); /* Adjust this value to match the padding */
    margin-left: -15px; /* Negative margin to offset the padding */
  }
}

.slider-container-2 {
  width: 100%;
  overflow: hidden; /* Ensure only the next slide is visible */
}

.slider-container-2 .slick-list {
  overflow: visible; /* Allows the next slide to be visible */
}

.slider-container-2 .slick-track {
  display: flex;
  align-items: center;
}

.slider-container-2 .slick-slide-2 {
  box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
  position: relative;
}

.slider-container-2 .slick-slide-2:not(:first-child) {
  visibility: visible;
}

.slider-container-2 .slick-slide-2:nth-child(n+3) {
  visibility: hidden; /* Hide all slides except the first and the next one */
}

.slider-container-2 .carousel-image-wrapper2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
}

.slider-container-2 .carousel-image-wrapper2 img {
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.slider-container-2 .carousel-caption2 {
  position: absolute;
  font-weight: 600;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  color: #7733FF;
  z-index: 2;
  background-color: white;
  border-radius: 20px;
}