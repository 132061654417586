.citation-card {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    width: 75%;
}

.citation-section-agenda {
    text-align: center;
    padding: 1.2rem 0;
    gap: 0.2rem;
}

.white-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.2rem;
}

.citation-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.citation-date-text,
.citation-date-text p, 
.citation-date-text-mobile,
.citation-date-text-mobile p {
    font-size: 1.1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 200;
}

.citation-date-text {
    line-height: 1.8rem;
}

.citation-date-text b, 
.citation-date-text-mobile b {
    font-weight: 500;
}

.citation-title,
.citation-date-text,
.citation-date-text p,
.citation-date-text-mobile,
.citation-date-text-mobile p {
    margin: 0;
    padding: 0;
}

.citation-title {
    white-space: nowrap;
    font-size: 2.2rem;
}

.citation-sm-text {
    color: #5f5f5f;
    font-size: 0.8rem;
}

.citation-buttons {
    display: flex;
    flex-direction: row;
    width: max-content;
    gap: 0.7rem;
}

.citation-buttons button, .citation-buttons a {
    font-size: 0.8rem;
    text-align: center;
    min-width: 10rem;
}

.citation-text {
    align-self: center;
    width: max-content;
    font-size: 1.1rem;
}

.icon {
    width: 1.4rem;
    height: 1.4rem;
    margin: 0;
    margin-right: 0.5rem;
}

.citation-date-text-mobile {
    display: none;
}

.citation-title-mobile {
    display: none;
}

/* x > Desktop 14' */
@media (min-width: 1000px) {

    .white-card-header {
        gap: 0.5rem;
    }
    .white-card {
        box-sizing: border-box;
        border: 1px solid #000;
        border-radius: 15px;
        padding: 1.2rem 1rem; 
        background-color: white;
    }

}

/* Ipad Pro 11' */
@media (max-width: 1000px) {
    .citation-card {
        width: 100%;
        flex-direction: column;
        box-sizing: border-box;
        border: 1px solid #000;
        border-radius: 15px;
        padding: 2rem 1.25rem; 
        background-color: white;
    }
    .citation-section:nth-child(2) > h1,
     .citation-section:nth-child(2) .citation-text, .citation-date-text, br {
        display: none;
    }

    .citation-section {
        width: 100%;
    }

    .citation-buttons, 
    .citation-buttons a {
        width: 100%;
    }

    .citation-buttons button, .citation-buttons a {
        font-size: 1rem;
    }

    .citation-date-text-mobile,
    .citation-title-mobile {
        display: block;
    }

    .citation-sm-text {
        font-size: 0.9rem;
        padding: 2rem 0 !important;
    }

    .citation-title {
        display: none;
    }
    .citation-title-mobile {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .citation-buttons {
        flex-direction: column;
    }

    .citation-section-agenda {
        padding: 0;
    }

    .citation-date-text-mobile,
    .citation-date-text-mobile p {
    font-size: 1rem;
    }
}

