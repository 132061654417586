/* service-carousel.css */
.service-carousel-section {
  height: 50vh;
}

.carousel-container {
  position: relative;
  width: 100%;
  margin-top: 2rem; /* Add margin to separate from the above section */
}

.background-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: right;
  z-index: -1; /* Ensure the image stays behind the text */
}

.image-container2 {
  position: absolute;
  right: -20%; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  z-index: -1; /* Ensure the image stays behind the text */
}

.carousel-item {
  padding: 10px; /* Add padding for spacing */
  box-sizing: border-box;
}

.carousel-image-wrapper {
  position: relative; /* Ensure the overlay is positioned correctly */
  border-radius: 10px; /* Match the image's rounded borders */
  overflow: hidden; /* Ensure the gradient doesn't spill over */
}

.carousel-image {
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  object-fit: cover;
}

.carousel-image-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(44, 0, 133, 0.04) 0%, rgba(44, 0, 133, 0.4) 70%);
  z-index: 1; /* Ensure the overlay is above the image but below the caption */
}

.carousel-caption {
  position: absolute !important;
  bottom: 15% !important;
  color: white;
  font-weight: 600;
  z-index: 2; /* Ensure the caption is above the overlay */
}

.carousel-controls {
  display: flex;
  justify-content: start !important;
  margin-top: 0 !important;
}

.carousel-control {
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control svg {
  width: 100%;
  height: 100%;
}

/* Target the last visible image to overflow on the right */
.carousel-item.last-visible {
  margin-right: -50px; /* Adjust the value as needed */
}

/* Mobile view styles */
.mobile-images {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the space between images */
}

.mobile-image-container {
  position: relative;
}

/* Ensure these styles apply to both carousel and mobile view */
.carousel-image-wrapper,
.mobile-image-container .carousel-image-wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.carousel-image,
.mobile-image-container .carousel-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.carousel-image-wrapper::before,
.mobile-image-container .carousel-image-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(44, 0, 133, 0.04) 0%, rgba(44, 0, 133, 0.4) 70%);
  z-index: 1;
}

.carousel-caption,
.mobile-image-container .carousel-caption {
  position: absolute !important;
  bottom: 0 !important;
  color: white;
  font-weight: 500;
  z-index: 2;
}

/* Hide carousel on mobile */
@media (max-width: 767px) {
  .carousel-container {
    display: none;
  }
}
