.schedule-card {
    display: grid;
    box-sizing: border-box;
    border: 1px solid #000;
    border-radius: 15px;
    padding: 0.8rem 2rem;
    background-color: white;
    align-items: center; 
    width: 75%;
    /* gap: 1.5rem; */
}

.schedule-form {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    align-content: center;
}

.schedule-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 2rem;
}

.schedule-form button {
    margin-left: auto;
}


/* Ipad Pro 11' */
@media (max-width: 1000px) {
    .schedule-card {
        width: 100%;
        padding: 1rem 1rem;
    }

    .schedule-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2rem;
    }

    .schedule-inputs, .schedule-inputs div {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
    }

    .schedule-form button {
        width: 100%;
    }
    
}
