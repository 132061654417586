.section {
  margin-top: 2.5rem;
}

.image-c {
    position: absolute;
    left: 0%; /* Adjust as needed */
    transform: translateY(30%);
    z-index: -1; /* Ensure the image stays behind the text */
  }
  
  .image-c img {
    height: 100%;
  }

  .image-c-second {
    position: absolute;
    right: 0%; /* Adjust as needed */
    transform: translateY(30%);
    z-index: -1; /* Ensure the image stays behind the text */
  }

  @media (max-width: 1000px ) {
    .section {
      margin: 0;
    }
  }