/* about-us-section.css */

.about-us-section {
    height: 50vh;
    position: relative;
  }
  
  .image-container {
    position: absolute;
    left: -200px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    z-index: -1; /* Ensure the image stays behind the text */
  }
  
  .image-container img {
    height: 100%;
  }
  
  .top-border {
    width: 172px;
    height: 0;
    border-top: 3px solid #47BCFF;
  }
  
  .bottom-border {
    width: 172px;
    height: 0;
    border-bottom: 3px solid #E485FF;
  }
  
.main-text {
    font-size: 3em; /* Default font size for mobile */
    letter-spacing: -0.02em;
    line-height: 1.1;
    padding: 1rem; /* Add some padding for mobile */
}

.title-tu-team {
  font-size: 1.56rem;
}

/* For screens larger than 768px (typical tablet and desktop sizes) */
@media (min-width: 768px) {
    .main-text {
        font-size: 3.3em;
        text-indent: 3em;
        padding: 0rem; /* Restore original padding */
        font-style: italic;
    }
}
