.card-bg {
  background-color: #E5F6FF;
  color: #220066;
  min-width: 120%;
  min-height: 26vh;
  position: relative;
  transition: all 0.3s ease;
  transform: translateY(20%);
}

.card-content {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%);
  width: 90%;
  text-align: start;
  transition: all 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 2000px) {
  .card-content h3 {
      font-size: 1.4rem;
  }
}


/* Responsive adjustments */
@media (max-width: 1400px) {
  .card-bg {
      min-width: 110%;
      min-height: 26vh;
      transform: translate(-70%, 55%);

  }
  .card-content h3 {
      font-size: 1.4rem;
  }
}

@media (max-width: 1200px) {
  .card-bg {
      min-width: 105%;
      min-height: 22vh;
      transform: translate(-110%, 50%);

  }
  .card-content h3 {
      font-size: 1.3rem;
  }
}

@media (max-width: 992px) {
  .card-bg {
      min-width: 100%;
      min-height: 20vh;
  }
  .card-content h3 {
      font-size: 1.2rem;
  }
}

@media (max-width: 850px) {
  .card-bg {
      min-width: 95%;
      min-height: 18vh;
  }
  .card-content h3 {
      font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .card-bg {
      display: none;
  }
}

.center-text {
    transform: translateX(-50%);
    left: 50%;
    bottom: 0%;
    font-size: 3.5em;
}

.image-layout {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
}

@media (max-width: 767px) {
    .card-bg-mobile {
      background-color: #E5F6FF;
      color: #220066;
      min-height: 150px; /* Adjust as needed */
    }
  
    .card-content-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      text-align: start;
      height: 100%;
    }
  
    .card-content-mobile h3 {
      font-size: 2rem;
      margin-top: 0.5rem;
      line-height: 1.2;
    }
  
    .flex-basis-50 {
      flex-basis: 50%;
    }
  }

.bg-blur-right-mobile{
    position: absolute;
    top: 0;
    left: -10%;
    z-index: -3;
    transform: translateY(0%);
}